import React, { Fragment, Component } from 'react';
import ReactDOM from 'react-dom';
import Hero from './Hero';
import HomeContent from './HomeContent';
import AWS from 'aws-sdk';
import Uppy from '@uppy/core'
// import {Dashboard} from '@uppy/dashboard'
// import Uppy from '@uppy/core'
// import {AwsS3Multipart} from '@uppy/aws-s3-multipart'
import { DashboardModal } from '@uppy/react'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import Swal from 'sweetalert2'
import { Auth } from 'aws-amplify';
import AwsS3MultipartHelper from './utility/aws_s3_multipart_helper'
import 'fs'
import axios from 'axios';
import ProgressBar from "@ramonak/react-progress-bar";
import Modal, {closeStyle} from 'simple-react-modal'
//import Cookies from 'universal-cookie';
import { JsonToTable } from "react-json-to-table";
import { config } from 'process';
import Collapsible from 'react-collapsible';
import multiDownload from 'multi-download';
import select_all from '../check2-square.svg';
import delete_all from '../x-octagon.svg';
import download_all from '../box-arrow-down.svg';

// import history from '../history';

// import { useHistory } from "react-router-dom";

// 
const AwsS3Multipart = require('@uppy/aws-s3-multipart')


// var openUppyModal=function(){
//   uppy.getPlugin("Dashboard").openModal()

// }

// var uppy=null;
// function displayUppyDashboard(datasetname){
//   uppy = Uppy.Core({
//     debug: true,
//     autoProceed: false,
//     restrictions: {
//       maxFileSize: 100000000,
//       maxNumberOfFiles: 30,
//       minNumberOfFiles: 1,
//       allowedFileTypes: ['*/*']
//     },
//     onBeforeFileAdded: (currentFile, files) => {
//       const modifiedFile = {
//       ...currentFile,
//       name: datasetname+'/'+currentFile.name
//     }
//     return modifiedFile

//   }
//     })
//     .use(Uppy.Dashboard, {
//     trigger: '.addphoto',
//     inline: false,
//     target: '.DashboardContainer',
//     replaceTargetContent: true,
//     showProgressDetails: true,
//     closeModalOnClickOutside:true,
//     note: 'Images and video only, 2–3 files, up to 1 MB',
//     height: 470,
//     metaFields: [
//       { id: 'name', name: 'Name', placeholder: 'file name' },
//       { id: 'caption', name: 'Caption', placeholder: 'describe what the image is about' }
//     ],
//     browserBackButtonClose: true
//     }).use(Uppy.AwsS3Multipart, {
//     limit: 20,
//     // companionUrl: 'http://127.0.0.1:3020'
//     companionUrl: ' https://b4xxb48wck.execute-api.us-east-1.amazonaws.com/prod'
//   })



//     uppy.on('complete', result => {
//     console.log('successful files:', result.successful)
//     console.log('failed files:', result.failed)
//     })

// }
// const uppy = Uppy({
//   debug: true,
//   autoProceed: false,
//   restrictions: {
//     maxFileSize: 100000000,
//     maxNumberOfFiles: 30,
//     minNumberOfFiles: 1,
//     allowedFileTypes: ['*/*']
//   },
//   onBeforeFileAdded: (currentFile, files) => {
//     const modifiedFile = {
//     ...currentFile,
//     // name: datasetname+'/'+currentFile.name
//     name:'test/test'
//   }
//   return modifiedFile

// }
//   });

//   uppy.use(AwsS3Multipart, {
//     limit: 20,
//     // companionUrl: 'http://127.0.0.1:3020'
//     companionUrl: ' https://b4xxb48wck.execute-api.us-east-1.amazonaws.com/prod'
//   });



//     uppy.on('complete', result => {
//     console.log('successful files:', result.successful)
//     console.log('failed files:', result.failed)
//     });

// uppy.use(Dashboard, {
//   trigger: '.addphoto',
//   inline: false,
//   target: '.DashboardContainer',
//   replaceTargetContent: true,
//   showProgressDetails: true,
//   closeModalOnClickOutside:true,
//   note: 'Images and video only, 2–3 files, up to 1 MB',
//   height: 470,
//   metaFields: [
//     { id: 'name', name: 'Name', placeholder: 'file name' },
//     { id: 'caption', name: 'Caption', placeholder: 'describe what the image is about' }
//   ],
//   browserBackButtonClose: true
//   });

// uppy.use(Dashboard, {
//   trigger: '.addphoto',
//   inline: false,
//   target: '.DashboardContainer',
//   replaceTargetContent: true,
//   showProgressDetails: true,
//   closeModalOnClickOutside:true,
//   note: 'Images and video only, 2–3 files, up to 1 MB',
//   height: 470,
//   metaFields: [
//     { id: 'name', name: 'Name', placeholder: 'file name' },
//     { id: 'caption', name: 'Caption', placeholder: 'describe what the image is about' }
//   ],
//   browserBackButtonClose: true
//   });



//   uppy.use(AwsS3Multipart, {
//   limit: 20,
//   // companionUrl: 'http://127.0.0.1:3020'
//   companionUrl: ' https://b4xxb48wck.execute-api.us-east-1.amazonaws.com/prod'
// });

var albumBucketName = "xtrodes-datasets";
var BucketName_transformed = "xtrodes-datasets-transformed";
var bucketRegion = "us-east-1";

// var IdentityPoolId = "us-east-1:2b2adca0-b99f-41d2-8092-a0969f3f6d2f";//unauthorized
var IdentityPoolId = "us-east-1:693df918-c3f5-4ebc-a47b-93e230c6b3df";//xtrodes authorized

// AWS.config.region = 'us-east-1'; // Region
// AWS.config.credentials = new AWS.CognitoIdentityCredentials({
//     IdentityPoolId: 'us-east-1:693df918-c3f5-4ebc-a47b-93e230c6b3df',
// }); 


var s3 = null;
var s3_transformed = null;
const django_url = 'https://api.xtrodes.com/'

// AWS.config.update({
//   region: bucketRegion,
//   credentials: new AWS.CognitoIdentityCredentials({
//     IdentityPoolId: IdentityPoolId
//   })
// });

// var params = { Bucket: albumBucketName }

// var s3 = new AWS.S3({
//   apiVersion: "2006-03-01",
//   params: params
// });
// var params_transformed = { Bucket: BucketName_transformed }
// var s3_transformed = new AWS.S3({apiVersion: "2006-03-01",
// params: params_transformed});

function hideDashboardIfExists() {
  var dashboard = document.getElementsByClassName("DashboardContainer")
  if (dashboard.length != 0) {



  }
}




function listDataSets() {
  return (<p>just a test</p>);
  // return (<div>
  //   this is only a test</div>)  ;
  // hideDashboardIfExists();
  // return s3.listObjects({ Delimiter: "/" }, function(err, data) {
  //   return(<div>
  //     only a test
  //     </div>);
  // });

}



// const deleteAlbum=(albumName)=>{
//   // var albumKey = encodeURIComponent(albumName) + "/";
//   var albumKey = albumName + "/";

//   s3.listObjects({ Prefix: albumKey }, function(err, data) {
//     if (err) {
//       return alert("There was an error deleting your album: ", err.message);
//     }
//     var objects = data.Contents.map(function(object) {
//       return { Key: object.Key };
//     });
//     s3.deleteObjects(
//       {
//         Delete: { Objects: objects, Quiet: true }
//       },
//       function(err, data) {
//         if (err) {
//           return alert("There was an error deleting your album: ", err.message);
//         }
//         alert("Successfully deleted album.");
//         // listAlbums();
//       }
//     );
//   });
// }


let current_datset = null;


var params = { Bucket: albumBucketName }


function blobToUint8Array(b) {
  var uri = URL.createObjectURL(b),
      xhr = new XMLHttpRequest(),
      i,
      ui8;
  
  xhr.open('GET', uri, false);
  xhr.send();
  
  URL.revokeObjectURL(uri);
  
  ui8 = new Uint8Array(xhr.response.length);
  
  for (i = 0; i < xhr.response.length; ++i) {
      ui8[i] = xhr.response.charCodeAt(i);
  }
  
  return ui8;
}

async function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsArrayBuffer(file.data);
  })
}



async function readFile(input,th) {
  let file = input;
  //=let reader = new FileReader();
  //console.error('zxcxz1')
  //reader.readAsArrayBuffer(file.data);
  //reader.onloadend = function() {
    //console.error('zxcxz')
    //console.error();
    //console.error(String.fromCharCode.apply(null, new Uint8Array(reader.result)))
    
    //placeholder
    let contentBuffer = await readFileAsync(file);
    var uuid = String.fromCharCode.apply(null, new Uint8Array(contentBuffer))
    th.state.file_uid_match[input.name] = uuid
    var path = th.state.uid_dict[uuid]
    //error_handle!!
    return (path)
  };





export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      modalOpenEDF:false,
      datasetname: null,
      previous_dataset:null,
      datasets: [],
      current_dataset_files: [],
      current_output_dataset_files: [],
      auth: props.auth,
      timer: null,
      split_status_timer: null,
      current_dataset_status: null,
      current_dataset_stage: null,
      current_dataset_progress: null, 
      current_dataset_processed_files: null,
      current_dataset_errors: null,
      show_split: false,
      split_history:[],
      split_status_timer:null,
      fixed_names_dict:{},
      fixed_names_zip:[],
      current_dataset_time:null,
      study_dict:{},
      current_study:null,
      show_move:false,
      recent_datasets: [[null,null],[null,null],[null,null],[null,null],[null,null],[null,null]],
      study_to_move:null,
      file_checkboxes:[],
      file_links:[],
      output_file_checkboxes:[],
      output_file_links:[],
      last_hrefs_raw:{},
      current_dataset_timestamp: null,
      last_launch_xf1_count: null,
      last_launch_xtr1_count: null,
      split_result:[],
      dataset_name_uid_match:{},
      file_uid_match : {},
      uid_dict: {},

    }
    if (props.auth.isAuthenticated) {
      const options = {
        bucket: 'xtrodes-datasets',
        acl: 'authenticated-read', // ACL you want to set for uploaded objects
        credentials: { // actual AWS credentials you want to use, we generate them using AWS STS GetFederationToken (https://docs.aws.amazon.com/STS/latest/APIReference/API_GetFederationToken.html)
          accessKeyId: props.auth.credentials.accessKeyId,
          secretAccessKey: props.auth.credentials.secretAccessKey,
          sessionToken: props.auth.credentials.sessionToken
        }
      };


      const optionsEDF = {
        bucket: 'xtrodes-datasets-transformed',
        acl: 'authenticated-read', // ACL you want to set for uploaded objects
        credentials: { // actual AWS credentials you want to use, we generate them using AWS STS GetFederationToken (https://docs.aws.amazon.com/STS/latest/APIReference/API_GetFederationToken.html)
          accessKeyId: props.auth.credentials.accessKeyId,
          secretAccessKey: props.auth.credentials.secretAccessKey,
          sessionToken: props.auth.credentials.sessionToken
        }
      };

      //this.cookies = new Cookies([this.state.auth.user_id['id']]);
      //this.cookies.get();
      const awsS3MultipartHelper = new AwsS3MultipartHelper({
        bucket: options.bucket,
        acl: options.acl,
        credentials: options.credentials,
      });


      const uppyOptions = {
        debug: true,
        
        note: "We accept DT1, DT8, NLE, TXT files.",
        locale: { strings: { dropPaste: "Drop files here, paste or %{browse}. We accept XF1, XF2, DT1, DT8, NLE, TXT, EDF files." } },
        autoProceed: false,
        autoRetry: true,
        restrictions: {
          maxFileSize: 5 * 1000000000,
          maxNumberOfFiles: 5000,
          minNumberOfFiles: 1,
          allowedFileTypes: ['.DT1', '.DT8', '.NLE', '.TXT', '.CSV', '.XF1','.EDF','.XF2']
        },
        onBeforeFileAdded: (currentFile, files) => {
          Object.keys(files).forEach(fileID => {
           // console.error(readFile(files[fileID]))
          })
          const modifiedFile = {
            ...currentFile,
            // name: 'public/cognito/xtrodesclient/us-east-1:9a3f32b3-3f65-4cee-9a33-bf956b0d58ab/'+this.state.datasetname+'/'+currentFile.name
            name: 'public/cognito/xtrodesclient/' + this.state.auth.user_id['id'] + '/' + this.state.datasetname + '/' + currentFile.name
          }
          return modifiedFile

        },
        
        

      };


      const uppyOptionsxf1 = {
        debug: true,
        note: "We accept XF1 and XF2 files.",
        locale: { strings: { dropPaste: "Drop files here, paste or %{browse}. We accept XF1 files." } },
        autoProceed: false,
        autoRetry: true,
        restrictions: {
          maxFileSize: 100000000,
          maxNumberOfFiles: 5000,
          minNumberOfFiles: 1,
          allowedFileTypes: ['.XF1','XF2,']
        },
        onBeforeFileAdded: (currentFile, files) => {
          const modifiedFile = {
            ...currentFile,
            // name: 'public/cognito/xtrodesclient/us-east-1:9a3f32b3-3f65-4cee-9a33-bf956b0d58ab/'+this.state.datasetname+'/'+currentFile.name
            name: 'public/cognito/xtrodesclient/' + this.state.auth.user_id['id'] + '/' + this.state.datasetname + '/' + currentFile.name
          }
          return modifiedFile

        },
        
        
        onBeforeUpload: (files) => {
          const uploadFiles = []
          const curDatasetFiles = []
          const remove_ids=[]
          const updatedFiles = {}
    Object.keys(files).forEach(fileID => {
      uploadFiles.push(`${files[fileID].name}`.split('/').pop())
    })
    this.state.current_dataset_files.forEach((element) => {
      curDatasetFiles.push(element["photoKey"])
    });
          const filteredArray = uploadFiles.filter(value => curDatasetFiles.includes(value));
          if (filteredArray.length>0)
          {
            Object.keys(files).forEach(fileID => {
              if (!filteredArray.includes(`${files[fileID].name}`.split('/').pop()))
              updatedFiles[fileID] = {
                ...files[fileID],
              }
            })
             alert('Files with the same names already exist')
            return false      
          }
        }
      };

      const awsS3MultipartOptions = {
        limit: options.limit || 5,
        // retryDelays: [0, 1000, 3000, 5000,5000,5000, 5000,5000,5000, 5000,5000,5000, 5000,5000,5000, 5000,5000,5000],

        createMultipartUpload: (file) => awsS3MultipartHelper.createMultipartUpload(file),
        listParts: (file, options) => awsS3MultipartHelper.listParts(file, options),
        prepareUploadPart: (file, options) => awsS3MultipartHelper.prepareUploadPart(file, options),
        abortMultipartUpload: (file, options) => awsS3MultipartHelper.abortMultipartUpload(file, options),
        completeMultipartUpload: (file, options) => awsS3MultipartHelper.completeMultipartUpload(file, options),
      }


      this.uppy = new Uppy(uppyOptions).use(AwsS3Multipart, awsS3MultipartOptions);
     /*  this.uppy.on('upload-success', (file, response) => {
        this.state.split_history.push(this.state.dataset_name_uid_match[this.state.file_uid_match[file.name]])
        console.log(file.name)
        console.log(this.state.file_uid_match)
        console.log(this.state.uid_dict)

        console.log(this.state.split_history)
        Swal.fire(JSON.stringify(this.state.split_history))

        
      }) */
      this.uppy_split = new Uppy(uppyOptionsxf1).use(AwsS3Multipart, awsS3MultipartOptions);
      this.uppy_xf1 = new Uppy(uppyOptionsxf1).use(AwsS3Multipart, awsS3MultipartOptions);
    }
    else {
      this.uppy = null;
      this.uppy_split = null;

    }

    // this.uppy = new Uppy({
    //   debug: true,
    //   autoProceed: false,
    //   restrictions: {
    //     maxFileSize: 100000000,
    //     maxNumberOfFiles: 30,
    //     minNumberOfFiles: 1,
    //     allowedFileTypes: ['*/*']
    //   },
    //   onBeforeFileAdded: (currentFile, files) => {
    //     const modifiedFile = {
    //     ...currentFile,
    //     name: this.state.datasetname+'/'+currentFile.name
    //   }
    //   return modifiedFile

    // }
    //   }).use(AwsS3Multipart, {
    //       limit: 20,
    //       // companionUrl: 'http://127.0.0.1:3020'
    //       companionUrl: ' https://b4xxb48wck.execute-api.us-east-1.amazonaws.com/prod'
    //     })

    this.displayDatasets2.bind(this);
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    // this.props.auth.user

  }




  componentWillUnmount() {
    this.uppy.close()
  }

  


  handleOpen() {
    if (this.state.datasetname === null) {
      alert('You need to select a Dataset first')
      return
    }
    this.setState({
      modalOpen: true
    })
  }

  handleOpenEDF() {
    if (this.state.datasetname === null) {
      alert('You need to select a Dataset first')
      return
    }
    this.setState({
      modalOpenEDF: true
    })
  }

  handleCloseEDF() {
    this.setState({
      modalOpenEDF: false
    })
    //if (this.state.datasetname=='split')
    //{this.setState({
    //  datasetname: null
    //})}
    this.uppy.reset();
    this.uppy_split.reset();
    this.uppy_xf1.reset();
    this.viewAlbum(this.state.datasetname,this.state.current_study, false)
    this.renewMainSession();
  }
  handleClose() {
    this.setState({
      modalOpen: false
    })
    //if (this.state.datasetname=='split')
    //{this.setState({
    //  datasetname: null
    //})}
    this.uppy.reset();
    this.uppy_split.reset();
    this.viewAlbum(this.state.datasetname,this.state.current_study, false)
    this.renewMainSession();
  }

   get_split_status(){
     var table = "split_statuses";
     var identity = this.state.auth.user_id['id'];
     var that = this;
     var params = {
       TableName: table,
       Key: {
         "identity": identity,
         "dataset": 'split'
       },
       ProjectionExpression: "split_into,#st",
       ExpressionAttributeNames: {
         "#st": "status"
       }
     };
     this.docClient.get(params, function (err, data) {
       if (err) {
         console.error("Unable to read item. Error JSON:", JSON.stringify(err, null, 2));
       } else {
         if (data["Item"] != undefined) {
          that.setState({
           datasetname: 'split'
         });
         that.viewAlbum('split',that.state.current_study)
           that.setState({split_history: data["Item"] })
         }}

   })
 }

   show_split_history(){
     this.get_split_status() 
     this.setState({
         datasetname: 'split',
         current_study:'split'
       });
       this.viewAlbum('split','split')
     this.setState({show_split: true})
     if (this.state.split_status_timer == null)
           this.state.split_status_timer = setInterval(() => this.get_split_status(), 3000);
   }
 
   close_split_history(){
     this.setState({show_split: false})
     if (this.state.datasetname=='split')
     {this.setState({
       datasetname: null,
       current_study:null,
       current_dataset_files:[]
     })}
     clearInterval(this.state.split_status_timer);
                 this.setState({ split_status_timer: null })
   }

   close_move_window(){
    this.setState({show_move: false,study_to_move:null})
    
  }

  // deleteAlbum =(albumName)=>{
  //   // var albumKey = encodeURIComponent(albumName) + "/";
  //   var albumKey = albumName + "/";

  //   s3.listObjects({ Prefix: albumKey }, function(err, data) {
  //     if (err) {
  //       return alert("There was an error deleting your album: ", err.message);
  //     }
  //     var objects = data.Contents.map(function(object) {
  //       return { Key: object.Key };
  //     });
  //     s3.deleteObjects(
  //       {
  //         Delete: { Objects: objects, Quiet: true }
  //       },
  //       function(err, data) {
  //         if (err) {
  //           return alert("There was an error deleting your album: ", err.message);
  //         }
  //         alert("Successfully deleted album.");
  //         // listAlbums();
  //       }
  //     );
  //   });
  // }


  async componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      AWS.config.update({
        region: bucketRegion,
        credentials: this.props.auth.credentials
      });
      this.docClient  = new AWS.DynamoDB.DocumentClient();

      var params = { Bucket: albumBucketName }

      s3 = new AWS.S3({
        apiVersion: "2006-03-01",
        params: params
      });
      var params_transformed = { Bucket: BucketName_transformed }
      s3_transformed = new AWS.S3({
        apiVersion: "2006-03-01",
        params: params_transformed
      });
      // AWS.config.update({region: bucketRegion,
      //   credentials: this.props.auth.credentials});
      this.get_recent()
      //this.listAlbums();
      this.listAlbums2();
      // this.props.auth.user
      // const response = await this.ListDataSets();
      // this.setState({data:response});

      // const response = await fetch(`https://api.coinmarketcap.com/v1/ticker/?limit=10`);
      // const json = await response.json();
      // this.setState({ data: json });
    }
    else {
      this.setState({ datasets: null })
    }
  }
  deletePhoto = (albumName, photoKey) => {
    if (!window.confirm('Are you sure you want to delete ' + photoKey + ' data file?'))
      return;
    if (!window.confirm('Please confirm deletion of ' + photoKey + ' again!'))
      return;
    // s3.deleteObject({ Key: 'public/cognito/xtrodesclient/us-east-1:9a3f32b3-3f65-4cee-9a33-bf956b0d58ab/'+albumName+'/'+photoKey }, (err, data)=>{
    s3.deleteObject({ Key: 'public/cognito/xtrodesclient/' + this.state.auth.user_id['id'] + '/' + albumName + '/' + photoKey }, (err, data) => {

      if (err) {
        return alert("There was an error deleting your file: ", err.message);
      }
      alert("Successfully deleted file.");
      this.viewAlbum(albumName,this.state.current_study);
    });
  }

  deletePhotos = (albumName, photoKeys) => {
    if (!window.confirm('Are you sure you want to delete selected files?'))
      return;

      const options = {
        Bucket: albumBucketName,
        Delete: {
            Objects: photoKeys,
            Quiet: false
        }
    };
    // s3.deleteObject({ Key: 'public/cognito/xtrodesclient/us-east-1:9a3f32b3-3f65-4cee-9a33-bf956b0d58ab/'+albumName+'/'+photoKey }, (err, data)=>{
    s3.deleteObjects(options, (err, data) => {

      if (err) {
        return alert("There was an error deleting your file: ", err.message);
      }
      alert("Successfully deleted files.");
      this.setState({file_links:[],file_checkboxes:[]})
      this.viewAlbum(albumName,this.state.current_study);
    });
  }
  // r = this;


  renewMainSession = async () => {
    const session = await Auth.currentSession();
    const credentials = await Auth.currentCredentials();
    this.state.auth.setCredentials(credentials);
    AWS.config.update({
      // region: bucketRegion,
      credentials: credentials
    });
    var params = { Bucket: albumBucketName }

    s3 = new AWS.S3({
      apiVersion: "2006-03-01",
      params: params
    });
    var params_transformed = { Bucket: BucketName_transformed }
    s3_transformed = new AWS.S3({
      apiVersion: "2006-03-01",
      params: params_transformed
    });

    // isAuthenticated:this.state.isAuthenticated,
    //   user:this.state.user,
    //   setAuthStatus:this.setAuthStatus,
    //   setUser:this.setUser,
    //   setCredentials:this.setCredentials,
    //   credentials:this.state.credentials,
    //   user_id:this.state.user_id

  }

  
  get_dataset_files= (albumName) => {
    var photos = s3.listObjects({ Prefix: 'public/cognito/xtrodesclient/' + this.state.auth.user_id['id'] + '/' + albumName+ '/', Delimiter: '/' }, (err, data) => {
    // let r = this;
    if (err) {
      console.log('view album error: ' + err.message);
      // this.renewMainSession();
      // return;
      // return;
      //     try{
      //       const session = await Auth.currentSession();
      // // this.setAuthStatus(true);
      //       const credentials = await Auth.currentCredentials()
      //       // Auth.signOut()
      //       this.props.auth.setAuthStatus(true);
      //       // this.props.auth.setUser(null);
      //       this.props.auth.setCredentials(credentials);
      //       // this.props.history.push("/");
      //     }catch(error){
      //       console.log(error.message)
      //     }
      // const history = useHistory();


      // history.push("/");

      // r.props.history.push('/');
      // this.props.history.push('/');
      // return;

      // return alert("There was an error viewing the Data-Set: " + err.message);
    }
    // 'this' references the AWS.Response instance that represents the response
    // var href = this.request.httpRequest.endpoint.href;
    var href = "https://s3.amazonaws.com/"
    // var href = '';
    var bucketUrl = href + albumBucketName + "/";
    var photos = data.Contents.map((photo, id) => {
      var photoKey = photo.Key;
      if (photoKey.endsWith('/'))
        return;
      var photoUrl = bucketUrl + photoKey;
      photoKey = photoKey.split('/')[photoKey.split('/').length - 1];


      
      return photoKey;
      // return (
      //   <div key={id}>
      //   <span onClick={()=>this.deletePhoto(albumName,photoKey)}>X</span>
      //   <span>{photoKey.replace(albumPhotosKey, "")}</span>
      //   </div>
      // );


      // return getHtml([
      //   "<span>",
      //   "<div>",
      //   "<span onclick=\"deletePhoto('" +
      //     albumName +
      //     "','" +
      //     photoKey +
      //     "')\">",
      //   "X",
      //   "</span>",
      //   "<span>",
      //   photoKey.replace(albumPhotosKey, ""),
      //   "</span>",
      //   "</div>",
      //   "</span>"
      // ]);
    });
    photos = photos.filter(function (item) {
      return typeof item != "undefined";
    })
    //console.error(photos)
    return photos
    
    // ReactDOM.render(photos, document.getElementById("aws-list-item-contents"));
    // return photos;
  })
  return photos
}

get_recent = () =>
{
  var table = "recent_datasets";

    var identity = this.state.auth.user_id['id'];
    var that = this
    var params = {
      TableName: table,
      Key: {
        "identity": identity,

      }
    };
    var d = [[null,null],[null,null],[null,null],[null,null],[null,null],[null,null]]
    this.docClient.get(params, function (err, data) {
      if (err) {
        console.error("Unable to read item. Error JSON:", JSON.stringify(err, null, 2));
      } else {
        if (data["Item"]) {
          
          for (var i=0; i<data['Item']['recent'].length;i++)
          {
            d[i][0] = data['Item']['recent'][i][0]
            d[i][1] = data['Item']['recent'][i][1]

          }
          
        }
        
      }    
      that.setState({ recent_datasets: d });
    }
    )
    
    //this.setState({ recent_datasets: d });
}

update_recent = () =>
{
  var table = "recent_datasets";

    var identity = this.state.auth.user_id['id'];
    var params = {
      TableName: table,
      Item:{
        "identity": identity,

        "recent":this.state.recent_datasets
    }
    };
    this.docClient.put(params, function (err, data) {
      if (err) {
        console.error("Unable to read item. Error JSON:", JSON.stringify(err, null, 2));
      }
    })
}

 deepCopy = (arr) => {
  let copy = [];
  arr.forEach(elem => {
    if(Array.isArray(elem)){
      copy.push(this.deepCopy(elem))
    }else{
      
        copy.push(elem)
      
    }
  })
  return copy;
}

  viewAlbum = async (albumName,study_name, check_status = true) => {
    this.setState({current_dataset_time: null})
    this.setState({current_study: study_name})
    this.setState({file_links: []})
    this.setState({output_file_links: []})
    this.setState({ current_dataset_files: [] });
    this.setState({ current_output_dataset_files: []});


    // this.renewMainSession();
    this.setState({ previous_dataset: this.state.datasetname });
    this.setState({ datasetname: albumName });
    let d = this.state.recent_datasets
    var tmp = this.deepCopy(d)
    if (albumName!=d[0][1] && albumName!=d[1][1] && albumName!=d[2][1]
     
    &&albumName!='split')
    {


      d[2]= tmp[1]
      d[1] = tmp[0]

      d[0][1] = albumName
      d[0][0] = study_name

    //this.cookies.set('third_recent', this.cookies.get('second_recent'), { path: '/',maxAge: 31536000 });
    //this.cookies.set('second_recent', this.cookies.get('first_recent'), { path: '/',maxAge: 31536000 });
    //this.cookies.set('first_recent', albumName, { path: '/',maxAge: 31536000  });
    }
    else if (albumName==d[1][1])
    {
      d[1] = tmp[0]
      d[0][1] = albumName
      d[0][0] = study_name

      //this.cookies.set('second_recent', this.cookies.get('first_recent'), { path: '/',maxAge: 31536000 });
      //this.cookies.set('first_recent', albumName, { path: '/',maxAge: 31536000  });    
    }
    else if (albumName==d[2][1])
    
    {
      d[2] = tmp[1]
      d[1] = tmp[0]
      d[0][1] = albumName
      d[0][0] = study_name

      //this.cookies.set('third_recent', this.cookies.get('second_recent'), { path: '/',maxAge: 31536000 });
      //this.cookies.set('second_recent', this.cookies.get('first_recent'), { path: '/',maxAge: 31536000 });
      //this.cookies.set('first_recent', albumName, { path: '/',maxAge: 31536000  });    
    }
    this.setState({ recent_datasets: d });
    this.update_recent()
    //if (this.state.timer!=null)
    //{
    //  clearInterval(this.state.timer)
    //  this.setState({timer:null})
    //}
    this.changeUppy();
    // current_datset = albumName;
    // displayUppyDashboard(albumName);
    // this.props.history.push('/');

    var albumPhotosKey = albumName + '/';


    // s3.listObjects({ Prefix: albumPhotosKey, Delimiter:'/' }, (err, data)=>{
    // s3.listObjects({ Prefix:'public/cognito/xtrodesclient/us-east-1:9a3f32b3-3f65-4cee-9a33-bf956b0d58ab/'+ albumPhotosKey, Delimiter:'/' }, (err, data)=>{
    var fileArray =  []
    let response = await s3.listObjects({ Prefix: 'public/cognito/xtrodesclient/' + this.state.auth.user_id['id'] + '/' + albumPhotosKey, Delimiter: '/' }).promise()
    response.Contents.forEach(element =>{fileArray.push(element)})
    while (response.IsTruncated) {
      response = await  s3.listObjects({ Prefix: 'public/cognito/xtrodesclient/' + this.state.auth.user_id['id'] + '/' + albumPhotosKey, Delimiter: '/',Marker: response.NextMarker }).promise()
     response.Contents.forEach(element =>{fileArray.push(element)})
     //fileArray.forEach(element => {
   }  //, (err, data) => {
      // let r = this;
      //if (err) {
      //  console.log('view album error: ' + err.message);
      //  // this.renewMainSession();
      //  // return;
      //  // return;
      //  //     try{
      //  //       const session = await Auth.currentSession();
      //  // // this.setAuthStatus(true);
      //  //       const credentials = await Auth.currentCredentials()
      //  //       // Auth.signOut()
      //  //       this.props.auth.setAuthStatus(true);
      //  //       // this.props.auth.setUser(null);
      //  //       this.props.auth.setCredentials(credentials);
      //  //       // this.props.history.push("/");
      //  //     }catch(error){
      //  //       console.log(error.message)
      //  //     }
      //  // const history = useHistory();
//
//
      //  // history.push("/");
//
      //  // r.props.history.push('/');
      //  // this.props.history.push('/');
      //  // return;
//
      //  // return alert("There was an error viewing the Data-Set: " + err.message);
      //}
      // 'this' references the AWS.Response instance that represents the response
      // var href = this.request.httpRequest.endpoint.href;
      var href = "https://s3.amazonaws.com/"
      // var href = '';
      var bucketUrl = href + albumBucketName + "/"; 
      var xf1=[]
      var xtr1=[]
      var js=[]
      var edf=[]
      var xf2=[]
      fileArray.forEach(element =>{
        if (element.Key.toLowerCase().endsWith('.xf1')) {xf1.push(element)}
        else if (element.Key.toLowerCase().endsWith('.xtr1')) {xtr1.push(element)}
        else if (element.Key.toLowerCase().endsWith('.json')) {js.push(element)}
        else if (element.Key.toLowerCase().endsWith('.edf')) {edf.push(element)}
        else if (element.Key.toLowerCase().endsWith('.xf2')) {xf2.push(element)}
        }
      )
      xf1.sort((a, b) => a.Key.localeCompare(b.Key, 'en', { sensitivity: 'base' }))
      xtr1.sort((a, b) => a.Key.localeCompare(b.Key, 'en', { sensitivity: 'base' }))
      js.sort((a, b) => a.Key.localeCompare(b.Key, 'en', { sensitivity: 'base' }))
      edf.sort((a, b) => a.Key.localeCompare(b.Key, 'en', { sensitivity: 'base' }))

      //xf2.sort((a, b) => a.Key.localeCompare(b.Key, 'en', { sensitivity: 'base' }))
      photos=[]
      photos = photos.concat(edf)
      photos = photos.concat(xtr1)
      photos = photos.concat(js)
      photos = photos.concat(xf1)
      photos = photos.concat(xf2)

      var photos = photos.map((photo, id) => {
        var lastModified = photo.LastModified.valueOf()
        var photoKey = photo.Key;
        if (photoKey.endsWith('/'))
          return;
        var photoUrl = bucketUrl + photoKey;
        photoKey = photoKey.split('/')[photoKey.split('/').length - 1];

        return { photoUrl: photoUrl, albumName: albumName, photoKey: photoKey, albumPhotosKey: albumPhotosKey, date:lastModified };
        // return (
        //   <div key={id}>
        //   <span onClick={()=>this.deletePhoto(albumName,photoKey)}>X</span>
        //   <span>{photoKey.replace(albumPhotosKey, "")}</span>
        //   </div>
        // );


        // return getHtml([
        //   "<span>",
        //   "<div>",
        //   "<span onclick=\"deletePhoto('" +
        //     albumName +
        //     "','" +
        //     photoKey +
        //     "')\">",
        //   "X",
        //   "</span>",
        //   "<span>",
        //   photoKey.replace(albumPhotosKey, ""),
        //   "</span>",
        //   "</div>",
        //   "</span>"
        // ]);
      });
      photos = photos.filter(function (item) {
        return typeof item != "undefined";
      })
      this.setState({ current_dataset_files: photos });
      // ReactDOM.render(photos, document.getElementById("aws-list-item-contents"));
      // return photos;
    
    
    var output_s3_transformed = null;
    // s3_transformed.listObjects({ Prefix:'public/cognito/xtrodesclient/us-east-1:9a3f32b3-3f65-4cee-9a33-bf956b0d58ab/'+ albumPhotosKey, Delimiter:'/' }, (err, data)=>{
    s3_transformed.listObjects({ Prefix: 'public/cognito/xtrodesclient/' + this.state.auth.user_id['id'] + '/' + albumPhotosKey, Delimiter: '/' }, (err, data) => {

      if (err) {
        return alert("There was an error viewing the Data-Set: " + err.message);
      }
      // var href = this.request.httpRequest.endpoint.href;
      var href = "https://s3.amazonaws.com/";

      var bucketUrl = href + BucketName_transformed + "/";
      var output_headline = <p style="color: chocolate;font-size: 1.4em;font-weight: bold;text-decoration: underline;margin-block-start: 0;margin-block-end: 0.1em;">Processed Files</p>;
      // output_s3_transformed=output_headline;
      output_s3_transformed = data.Contents.map((file_name) => {
        if (file_name.Key.endsWith('/'))
          return;
        var temp_list = file_name.Key.split('/')
        var outputurl = bucketUrl + file_name.Key

        // var output_file_names= <div><a href={outputurl}>{temp_list[temp_list.length-1]}</a></div>
        // var output_file_names= <div>just a test</div>
        // return output_file_names;
        return { outputurl: outputurl, file_name: temp_list[temp_list.length - 1] };
        // return getHtml(output_file_names)

        // return output_file_names
      });
      output_s3_transformed = output_s3_transformed.filter(function (item) {
        return typeof item != "undefined";
      })
      //if (output_s3_transformed.length==0)
      //{
      //  this.testStatus()
      //  this.timer = setInterval(()=> this.testStatus(), 5000);
      //}
      //  this.testStatus()
      //  this.forceUpdate();
      //console.log(this.state.current_dataset_status)

      //if (this.state.current_dataset_status=="in progress" && this.state.timer==null)
      // {
      if (check_status) {
        this.testStatus();
        if (this.state.timer == null)
          this.state.timer = setInterval(() => this.testStatus(), 3000);
      }
      //}
      //this.timer = setInterval(()=> this.testStatus(), 5000);
      this.setState({ current_output_dataset_files: output_s3_transformed });
      // ReactDOM.render(output_s3_transformed, document.getElementById("aws-list-item-contents-output"));
      // document.getElementById("output_files").innerHTML = getHtml(output_headline);
      // document.getElementById("output").innerHTML = getHtml(output_s3_transformed);
      // document.getElementById("output_headline").innerHTML = getHtml(output_headline);
    })

  };

  

  downloadSelectedRaw = () =>{
    var tmp = []
    this.state.file_links.forEach(element => {
      tmp.push(this.downloadFile(albumBucketName, element))
    });
    multiDownload(tmp)

  }

  downloadSelectedOutput= () =>{
    var tmp = []
    this.state.output_file_links.forEach(element => {
      tmp.push(this.downloadFile(BucketName_transformed, element))
    });
    
    multiDownload(tmp)

  }

  deleteSelectedRaw = () =>{
    var tmp = []
    this.state.file_links.forEach(element => {
      tmp.push({Key : 'public/cognito/xtrodesclient/' + this.state.auth.user_id['id'] + '/' + this.state.datasetname + '/'+  element.split('/').pop()})
      
      
    });
    this.deletePhotos(this.state.datasetname, tmp)
    //console.error('files',tmp)
    //multiDownload(tmp)

  }

  listAlbums2 = () =>{
    this.get_recent()
    var url = django_url +  "sessionsv2/0/";
     var optionAxios = {
       headers: {
         'Authorization': 'Bearer ' + this.state.auth.user.signInUserSession.accessToken["jwtToken"],
       }
     };
     axios
       .get(url, optionAxios)
       .then((response) => {
        var jsonData = JSON.parse(JSON.stringify(response.data));
        var d = {} 
        var uid_d = {}
        var dataset_name_uid_dict ={}
        d['split']={}
        d['split']['split']={}
        for (var i = 0; i < jsonData.length; i++)
        {
            var study = jsonData[i];
            if(!d[study.id]){
              d[study.id]={};
          }
          for (var sess in study.sessions)
          {
          d[study.id][study.sessions[sess].Session_name]={'name': study.sessions[sess].Dateset_name.split('-.~')[0],'id':study.sessions[sess].id}
          //uid_d[study.sessions[sess].uuid] = study.sessions[sess].Dateset_name.split('-.~')[0]
          uid_d[study.sessions[sess].uuid] = study.sessions[sess].Session_name
          dataset_name_uid_dict[study.sessions[sess].uuid] = study.sessions[sess].Dateset_name.split('-.~')[0]

          }
          d[study.id]['study_name']= study.study_name
        }
        for (let key in d)
        {
         // d[key] = d[key].sort(function(a, b) { Object.values(a)[0]['name'].localeCompare(Object.values(b)[0]['name'], 'en', { sensitivity: 'base' })})
          this.renameFiles2(d[key])
        }
        //console.error(uid_d)
        this.setState({ study_dict: d,uid_dict: uid_d, dataset_name_uid_match:dataset_name_uid_dict});
       })  
  }

   download_files(files) {
    files.forEach(element => {
      this.download_next(element)
    });
  }


  download_next(i) {
    var a = document.createElement('a');
    a.href = i;
    //a.target = '_parent';
    // Use a.download if available, it prevents plugins from opening.
    //if ('download' in a) {
    //  a.download = files[i].filename;
    //}
    // Add a to the doc for click to work.
    (document.body || document.documentElement).appendChild(a);
    if (a.click) {
      a.click(); // The click method is supported by most browsers.
    } else {
      a.click(); // Backup using jquery
    }
    // Delete the temporary link.
    a.parentNode.removeChild(a);
    // Download the next file with a small timeout. The timeout is necessary
    // for IE, which will otherwise only download the first file.
    
  }

  listAlbums = async () => {
    // s3://xtrodes-datasets/public/cognito/xtrodesclient/us-east-1:9a3f32b3-3f65-4cee-9a33-bf956b0d58ab/NEUR0007.DT8
    // us-east-1:9a3f32b3-3f65-4cee-9a33-bf956b0d58ab
    // let response = await s3.listObjects({Prefix:'public/cognito/xtrodesclient/us-east-1:9a3f32b3-3f65-4cee-9a33-bf956b0d58ab/' ,Delimiter: "/" }).promise();
    let response = await s3.listObjects({ Prefix: 'public/cognito/xtrodesclient/' + this.state.auth.user_id['id'] + '/', Delimiter: "/" }).promise();

    let albums = response.CommonPrefixes.map((commonPrefix, id) => {
      var prefix = commonPrefix.Prefix;
      var albumName = prefix.split('/');
      return albumName[albumName.length - 2]
      // var albumName = decodeURIComponent(prefix.replace("/", ""));
      // return albumName;
    });
    // return (<li key={id}><span onClick={()=>deleteAlbum(albumName)}>X</span><span onClick={()=>this.viewAlbum(albumName).bind(this)}>{albumName}</span></li>)});
    // albums = null;
    var arr = albums.sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'base' }))
    var arr2 = arr.map(x => x.split('-.~')[0])
    arr2 = this.renameFiles(arr2) 
    var arr3=this.zip(arr,arr2)
    //arr = this.zip(arr,arr2)
    var result = {};
    arr.forEach((key, i) => result[key] = arr2[i]);  
    this.setState({ fixed_names_dict: result});
    this.setState({ fixed_names_zip: arr3});
    this.setState({ datasets: albums })


    // s3.listObjects({ Delimiter: "/" }, function(err, data) {
    //   if (err) {
    //     return alert("There was an error listing your albums: " + err.message);
    //   } else {
    //     var albums = data.CommonPrefixes.map(function(commonPrefix,id) {
    //       var prefix = commonPrefix.Prefix;
    //       var albumName = decodeURIComponent(prefix.replace("/", ""));
    //       return (<li key={id}><span onClick={()=>deleteAlbum(albumName)}>X</span><span onClick={()=>this.viewAlbum(albumName).bind(this)}>{albumName}</span></li>)})
    //     //   return getHtml([
    //     //     "<li>",
    //     //     "<span onclick=\"deleteAlbum('" + albumName + "')\">X</span>",
    //     //     "<span onclick=\"viewAlbum('" + albumName + "')\">",
    //     //     albumName,
    //     //     "</span>",
    //     //     "</li>"
    //     //   ]);
    //     // });
    //     var message = albums.length
    //       ? ([
    //           // "<p>Click on the Data-Set to view it.</p>",
    //           // "<p>Click on the X to delete the Data-Set.</p>"
    //         ])
    //       : "<p>You do not have any Data-Set. Please Create a Data-Set.";
    //     var htmlTemplate = (<div>{message}<ul>{albums}</ul></div>);
    //     // var htmlTemplate = (<div>message</div>);

    //     ReactDOM.render(htmlTemplate, document.getElementById("aws-list"));



    //   }
    // });
  }


  // ListDataSets = async (props)=>{
  //   // return <p>just a test</p>;
  //   // return (<div>
  //   //   this is only a test</div>)  ;
  //   // hideDashboardIfExists();
  //     // s3.listObjectsV2()
  //     var d=null;
  //     try{
  //     d = await s3.listObjectsV2().promise();
  //     }
  //     catch(error)
  //     {
  //       console.log(error.message)
  //       return <p>{error.message}</p>
  //     }
  //     // console.log(d);

  //     var albums = await d.CommonPrefixes.map(function(commonPrefix) {
  //       var prefix = commonPrefix.Prefix;
  //       var albumName = decodeURIComponent(prefix.replace("/", ""));
  //       console.log(albumName);
  //       return (<li>{albumName}</li>);
  //       // return getHtml([
  //       //   "<li>",
  //       //   "<span onclick=\"deleteAlbum('" + albumName + "')\">X</span>",
  //       //   "<span onclick=\"viewAlbum('" + albumName + "')\">",
  //       //   albumName,
  //       //   "</span>",
  //       //   "</li>"
  //       // ]);
  //     });
  //     return <div>{albums}</div>;
  //     // s3.listObjectsV2({ Delimiter: "/" }, function(err, data) {
  //     //   return(<div>
  //     //     only a test
  //     //     </div>);
  //     // });

  // };
  // render(){return <div id="aws-list"></div>};
  isEven(val) {
    return val % 2 === 0 ? "Even" : "Odd";
  }
  handleClick = () => this.setState({ count: 1 });




   handleDatasetFileCheckboxes = (position) => {
     var tmp = this.state.file_checkboxes
    tmp[position] = !tmp[position]
    //console.error(this.state.file_checkboxes,position,tmp,this.state.file_links)
    var tmp2 = this.state.file_links
    if (tmp2.includes(this.state.current_dataset_files[position]['photoUrl']))
    {
      tmp2 = tmp2.filter(e => e !== this.state.current_dataset_files[position]['photoUrl']);
    }
    else{
      tmp2.push(this.state.current_dataset_files[position]['photoUrl'])

    }
    this.setState({file_checkboxes:tmp,file_links:tmp2})
   }

   handleOutputDatasetFileCheckboxes = (position) => {
    var tmp = this.state.output_file_checkboxes
   tmp[position] = !tmp[position]
   //console.error(this.state.file_checkboxes,position,tmp,this.state.file_links)
   var tmp2 = this.state.output_file_links
   if (tmp2.includes(this.state.current_output_dataset_files[position]['outputurl']))
   {
     tmp2 = tmp2.filter(e => e !== this.state.current_output_dataset_files[position]['outputurl']);
   }
   else{

     tmp2.push(this.state.current_output_dataset_files[position]['outputurl'])

   }
   this.setState({output_file_checkboxes:tmp,output_file_links:tmp2})
  }

  displayDatasetFiles = () => {
    if (!this.state.current_dataset_files) {
      // this.setState({refresh:false})
      return 'No files yet!'
    }
    else {
      // this.setState({refresh:false})
      // this.state.datasets.forEach(dataset_name=>)
      
      var tmp_file_checkboxes = [...new Array(this.state.current_dataset_files.length)].map(x => false);

      return this.state.current_dataset_files.map((file_prop, id) => {
        // return {albumName:albumName,photoKey:photoKey,albumPhotosKey:albumPhotosKey};
        // return (
        //   <div key={id}>
        //   <span onClick={()=>this.deletePhoto(file_prop['albumName'],file_prop['photoKey'])}>X </span>
        //   <span>{file_prop['photoKey'].replace(file_prop['albumPhotosKey'], "")}</span>
        //   </div>



        // );
        if ( this.state.file_checkboxes.length == 0 || this.state.datasetname!=this.state.previous_dataset || this.state.file_checkboxes.length != this.state.current_dataset_files.length)
        {
        this.state.file_checkboxes = tmp_file_checkboxes 
        this.state.last_hrefs_raw =[]
        }
        var sp = file_prop['photoKey'].replace(file_prop['albumPhotosKey'], "").split('-.~')
        if (sp.length>1)
        {
          sp = this.state.study_dict[this.state.current_study][this.state.datasetname]['name'] + '_' + sp[sp.length-1].split('_').slice(3).join('_')
        }
        return (<div key={id}>
          
          <div className="dataset-div">
          {this.state.datasetname !== 'split' ?
          (
          <input    className = "file_checkbox"
                    type="checkbox"
                    id={`output-checkbox-${id}`}
                    //name={'1'}
                   // value={'1'}
                    checked={this.state.file_checkboxes[id]}
                    onChange={() => this.handleDatasetFileCheckboxes(id)}
                  />) : (null)}
            <span onClick={() => this.deletePhoto(file_prop['albumName'], file_prop['photoKey'])}>
            <img className="buttonDeleteDataset" src={require('../delete-icon-green.png')}></img></span><span className='span-dataset-datafile-near-image'>
            <a href={!Object.keys(this.state.last_hrefs_raw).includes(file_prop['photoUrl']) ? (this.downloadFile(albumBucketName, file_prop['photoUrl'])):(this.state.last_hrefs_raw[file_prop['photoUrl']]) }>
                  {sp}</a></span></div></div>);
        // return (<li key={id}><span onClick={()=>this.deleteAlbum(dataset_name)}>X</span><span onClick={()=>this.viewAlbum(dataset_name)}>{dataset_name}</span></li>)
      });

    }

  }


  displayOutputDatasetFiles = () => {
    if (!this.state.datasetname) return
    if (this.state.current_output_dataset_files.length == 0) {
      // this.setState({refresh:false})
      if (this.state.current_dataset_files.length != 0) {
        return <div className='no-output-files-yet'></div>
      }
      else {
        return <div className='no-output-files-yet'>No Dataset files yet!</div>

      }

    }
    else {

      // this.setState({refresh:false})
      // this.state.datasets.forEach(dataset_name=>)
      var tmp_file_checkboxes = [...new Array(this.state.current_output_dataset_files.length)].map(x => false);
      return this.state.current_output_dataset_files.map((file_prop, id) => {
        if ( this.state.output_file_checkboxes.length == 0 || this.state.datasetname!=this.state.previous_dataset)
        {
        this.state.output_file_checkboxes = tmp_file_checkboxes 
        }
        // return {albumName:albumName,photoKey:photoKey,albumPhotosKey:albumPhotosKey};
        // return (
        //   <div key={id}>
        //   <span onClick={()=>this.deletePhoto(file_prop['albumName'],file_prop['photoKey'])}>X</span>
        //   <span>{file_prop['photoKey'].replace(file_prop['albumPhotosKey'], "")}</span>
        //   </div>
        // );
        var sp = file_prop['outputurl'].split('-.~')[0].split('/').pop()
        if  (file_prop["file_name"].includes(sp+'_')) {
        sp = file_prop["file_name"].replace(sp+'_', '')
        sp = this.state.study_dict[this.state.current_study][this.state.datasetname]['name'] + '_' + sp
      }
        else {
          if (file_prop["file_name"]!='XTR Support'){
          var r = file_prop["file_name"].split('_').splice(1).join('_')}
          else
          {
            var r =file_prop["file_name"]
          }

          sp = this.state.study_dict[this.state.current_study][this.state.datasetname]['name'] + '_' + r
        }
        //if (sp.length>1)
        //{
        //  sp = sp.slice(1).join('_')
        //}
        return <div key={id}>
          <input
                    className = "file_checkbox"
                    type="checkbox"
                    id={`output-checkbox-${id}`}
                    name={'1'}
                    value={'1'}
                    checked={this.state.output_file_checkboxes[id]}
                    onChange={() =>this.handleOutputDatasetFileCheckboxes(id)}
                  />
          <a href={this.downloadFile(BucketName_transformed, file_prop['outputurl'])}>{sp}</a>
          </div>
        //  return <div key={id}><button className="button" onClick={()=>this.downloadFile(BucketName_transformed,file_prop['outputurl'])}>{file_prop['file_name']}</button></div>
        // var output_file_names= <div>just a test</div>
        // return output_file_names;
        // return {outputurl:outputurl,file_name:temp_list[temp_list.length-1]};
        // return (<li key={id}><span onClick={()=>this.deleteAlbum(dataset_name)}>X</span><span onClick={()=>this.viewAlbum(dataset_name)}>{dataset_name}</span></li>)
      });
    }

  }

   renameFiles(arr){
    var count = {};
    arr.forEach(function(x,i) {
  
      if ( arr.indexOf(x) !== i ) {
        var c = x in count ? count[x] = count[x] + 1 : count[x] = 1;
        var j = c + 1;
        var k = x + ' (' + j + ')';
  
        while( arr.indexOf(k) !== -1 ) k = x + '(' + (++j) + ')';
        arr[i] = k;
      }
    });
    return arr;
  }

  renameFiles2(arr){
    for (let k in arr) {
      var count = 1
      var name1 = arr[k]['name']
      for (let z in arr) {
        if (k!=z)
        {
          if (name1 == arr[z]['name'])
          {
            arr[z]['name'] = arr[z]['name'] + ' (' + (++count) + ')'
          }
        }
      
  }
}
  }


   zip(a,b){
    // pre-allocate an array to hold the results 
    var rval=Array(Math.max(a.length, b.length));
    for( var i=0; i<rval.length; i++){ 
        rval[i]=[a[i],b[i]] 
    }
    return rval
}


  //displayDatasets = () => {
  //  const highlight = { color: 'red' };
//
  //  console.error(this.state.study_dict)
  //  if (!this.state.datasets) {
  //    // this.setState({refresh:false})
  //    return 'No datasets yet!'
  //  }
  //  else {
  //    // this.setState({refresh:false})
  //    // this.state.datasets.forEach(dataset_name=>)
  //    var arr = this.state.datasets.sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'base' }))
  //    //var arr2 = arr.map(x => x.split('-.~')[0])
  //    //arr2 = this.renameFiles(arr2) 
  //    //arr = this.zip(arr,arr2)
  //    //var result = {};
  //    //arr.forEach((key, i) => result[key] = arr2[i]);  
  //    //this.setState({ fixed_names: result});
  //    //console.error(arr)
//
  //      return this.state.fixed_names_zip.map((dataset_name, id) => {
  //          if (dataset_name!='split')
  //          {
  //          return (<li key={id}><div className="dataset-div"><span onClick={() => this.deleteAlbum(dataset_name[0])}><img className="buttonDeleteDataset" src={require('../delete-icon-green.png')}></img></span><span onClick={() => this.viewAlbum(dataset_name[0])} className='span-dataset-datafile-near-image' style={(dataset_name[0] == this.state.datasetname) ? { color: "darkorchid", cursor: "pointer" } : { color: "darkslategrey", cursor: "pointer" }}>{dataset_name[1]}</span></div></li>)
  //          }
  //        });
  //    
  //    //console.error(this.state.fixed_names)
//
  //    //return arr.map((dataset_name, id) => {
  //    //  if (dataset_name!='split')
  //    //  {
  //    //  return (<li key={id}><div className="dataset-div"><span onClick={() => this.deleteAlbum(dataset_name)}><img className="buttonDeleteDataset" src={require('../delete-icon-green.png')}></img></span><span onClick={() => this.viewAlbum(dataset_name)} className='span-dataset-datafile-near-image' style={(dataset_name == this.state.datasetname) ? { color: "darkorchid", cursor: "pointer" } : { color: "darkslategrey", cursor: "pointer" }}>{dataset_name}</span></div></li>)
  //    //  }
  //    //});
  //  }
//
  //}

  rename_dataset(session, study,new_name)
  {
    if (!new_name) {return}
    if (!new_name) {
      return alert("Dataset name must contain at least one non-space character.");
    }
    if (new_name.indexOf("/") !== -1) {
      return alert("Dataset names cannot contain slashes.");
    }
    if (/\W/.test(new_name))
    {
      return alert("Dataset names can only contain letters (A-Z, a-z), numbers (0-9), and underscores (_).");
    }
    var url = django_url + "sessions/rename/" + this.state.study_dict[study][session]['id'] + '/';
     var optionAxios = {
       headers: {
         'Authorization': 'Bearer ' + this.state.auth.user.signInUserSession.accessToken["jwtToken"],
       }
     };
     this.state.study_dict[this.state.current_study][this.state.datasetname]['name']=new_name  

     this.state.last_hrefs_raw = []
     this.setState({last_hrefs_raw:[]})
     const data = { "Dateset_name": new_name,'identity': this.state.auth.user_id['id'],"IdToken": this.state.auth.user.signInUserSession.idToken["jwtToken"]}
     axios
       .put(url,data, optionAxios)
       .then((response) => {

         this.listAlbums2()
        this.displayOutputDatasetFiles()

       })
  }

  displayDatasets2 = () => {
    const highlight = { color: 'red' };

    if (!this.state.current_study) {
      // this.setState({refresh:false})
      return 'No datasets yet!'
    }
    else {
      // this.setState({refresh:false})
      // this.state.datasets.forEach(dataset_name=>)
      //var arr = this.state.datasets.sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'base' }))
      //var arr2 = arr.map(x => x.split('-.~')[0])
      //arr2 = this.renameFiles(arr2) 
      //arr = this.zip(arr,arr2)
      //var result = {};
      //arr.forEach((key, i) => result[key] = arr2[i]);  
      //this.setState({ fixed_names: result});
      //console.error(arr)
      var tmp = {}
     // for (let key in this.state.study_dict) {
        tmp[this.state.current_study] = []
        for (let key2 in this.state.study_dict[this.state.current_study]){
          if (key2!='study_name')
          tmp[this.state.current_study].push([key2,this.state.study_dict[this.state.current_study][key2]['name']] )
        }
        tmp[this.state.current_study] = tmp[this.state.current_study].sort((a, b) => a[1].localeCompare(b[1], 'en', { sensitivity: 'base' }))
     //}
       // return Object.keys(this.state.study_dict).sort().map((study, id) => {
           // return (<Collapsible trigger={study} onClick={() => console.error(13213)} >
              return tmp[this.state.current_study].map((session,id) =>{
                if (typeof this.state.current_study != "undefined")
                return (<li key={id}><div className="dataset-div"><span onClick={() => this.deleteAlbum(session[0],this.state.current_study)}><img className="buttonDeleteDataset" src={require('../delete-icon-green.png')}></img></span><span onClick={() => this.viewAlbum(session[0],this.state.current_study)} className='span-dataset-datafile-near-image' style={(session[0] == this.state.datasetname) ? { color: "darkorchid", cursor: "pointer" } : { color: "darkslategrey", cursor: "pointer" }}>{session[1]}</span></div></li>)
              })
               //</Collapsible>)
            
          
      
      //console.error(this.state.fixed_names)

      //return arr.map((dataset_name, id) => {
      //  if (dataset_name!='split')
      //  {
      //  return (<li key={id}><div className="dataset-div"><span onClick={() => this.deleteAlbum(dataset_name)}><img className="buttonDeleteDataset" src={require('../delete-icon-green.png')}></img></span><span onClick={() => this.viewAlbum(dataset_name)} className='span-dataset-datafile-near-image' style={(dataset_name == this.state.datasetname) ? { color: "darkorchid", cursor: "pointer" } : { color: "darkslategrey", cursor: "pointer" }}>{dataset_name}</span></div></li>)
      //  }
      //});
}

  }

  viewStudy = (study) => {
    this.setState({ current_study: study,datasetname: null,current_dataset_time: null,
      current_output_dataset_files:[],current_dataset_files:[] })
      let d = this.state.recent_datasets
      var tmp = this.deepCopy(d)
      if (study!=d[3][0] && study!=d[4][0] && study!=d[5][0]
     
        &&study!='split')
        {
   
    
          d[5]= tmp[4]
          d[4] = tmp[3]
    
          d[3][1] = this.state.study_dict[study]['study_name']
          d[3][0] = study
    
        //this.cookies.set('third_recent', this.cookies.get('second_recent'), { path: '/',maxAge: 31536000 });
        //this.cookies.set('second_recent', this.cookies.get('first_recent'), { path: '/',maxAge: 31536000 });
        //this.cookies.set('first_recent', albumName, { path: '/',maxAge: 31536000  });
        }
        else if (study==d[1][1])
        {
          d[4] = tmp[3]
          d[3][1] = this.state.study_dict[study]['study_name']
          d[3][0] = study
    
          //this.cookies.set('second_recent', this.cookies.get('first_recent'), { path: '/',maxAge: 31536000 });
          //this.cookies.set('first_recent', albumName, { path: '/',maxAge: 31536000  });    
        }
        else if (study==d[2][1])
        
        {
          d[5] = tmp[4]
          d[4] = tmp[3]
          d[3][1] = this.state.study_dict[study]['study_name']
          d[3][0] = study
    
          //this.cookies.set('third_recent', this.cookies.get('second_recent'), { path: '/',maxAge: 31536000 });
          //this.cookies.set('second_recent', this.cookies.get('first_recent'), { path: '/',maxAge: 31536000 });
          //this.cookies.set('first_recent', albumName, { path: '/',maxAge: 31536000  });    
        }
        this.setState({ recent_datasets: d });
    this.update_recent()
  }

  displayStudies = () => {
    const highlight = { color: 'red' };

  
        return Object.keys(this.state.study_dict).sort().map((study, id) => {
          if (study!='split')

              return(<li key={id}><div className="dataset-div"><span onClick={() => this.deleteStudy(study)}><img className="buttonDeleteDataset" src={require('../delete-icon-green.png')}></img></span><span onClick={() => this.viewStudy(study)} className='span-dataset-datafile-near-image' style={(study == this.state.current_study) ? { color: "darkorchid", cursor: "pointer" } : { color: "darkslategrey", cursor: "pointer" }}>{this.state.study_dict[study]['study_name']}</span></div></li>)

            
          });
  }

  displayStudiesForMove = () => {
    const highlight = { color: 'red' };

  
        return Object.keys(this.state.study_dict).sort().map((study, id) => {

              return(<li key={id}><div className="dataset-div"><span onClick={() => this.setState({study_to_move:study})} className='span-dataset-datafile-near-image' style={(study == this.state.study_to_move) ? { color: "darkorchid", cursor: "pointer" } : { color: "darkslategrey", cursor: "pointer" }}>{this.state.study_dict[study]['study_name']}</span></div></li>)

            
          });
  }

  displayRecentDatasets = () => {
    const highlight = { color: 'red' };
    //console.error('zxcxzc2',this.state.study_dict)
    //console.error('qqqqq',this.state.study_dict['blabla222']['bla1222']);

    if (!this.state.recent_datasets) {
      // this.setState({refresh:false})
    }
    else {
      // this.setState({refresh:false})
      // this.state.datasets.forEach(dataset_name=>)
      //let recent= [this.cookies.get('first_recent'),this.cookies.get('second_recent'),this.cookies.get('third_recent') ]
      return this.state.recent_datasets.slice(0,3).map((dataset_name, id) => {
        {

         // console.error('zzzzzzzzz',dataset_name[1]);

          //console.error('zzzzzzzzz',this.state.study_dict[dataset_name[0]]);
          //console.error('zzzzzzzzz',this.state.study_dict);
         // console.error('zzzzzzzzz',this.state.study_dict['blabla2223']);
          if (dataset_name[0] in this.state.study_dict)
          {
            if (dataset_name[1] in this.state.study_dict[dataset_name[0]])
            {if (dataset_name.length==2 && dataset_name[0]!=0 && dataset_name[1]!=0 && dataset_name[0]!=null && dataset_name[1]!=null && this.state.study_dict) { return (<li key={id}><div className="dataset-div"><span onClick={() => this.deleteAlbum(dataset_name[1],dataset_name[0])}><img className="buttonDeleteDataset" src={require('../delete-icon-green.png')}></img></span><span onClick={() => this.viewAlbum(dataset_name[1],dataset_name[0])} className='span-dataset-datafile-near-image' style={(dataset_name[1] == this.state.datasetname) ? { color: "darkorchid", cursor: "pointer" } : { color: "darkslategrey", cursor: "pointer" }}>{dataset_name[1] !== undefined && dataset_name[1] !== 'undefined' ? ( this.state.study_dict[dataset_name[0]][dataset_name[1]]['name']) :(null)}</span></div></li>)
          }}
          }
         }
      });
    }

  }

  displayRecentStudies = () => {
    const highlight = { color: 'red' };
    //console.error('zxcxzc2',this.state.study_dict)
    //console.error('qqqqq',this.state.study_dict['blabla222']['bla1222']);

    if (!this.state.recent_datasets) {
      // this.setState({refresh:false})
    }
    else {
      // this.setState({refresh:false})
      // this.state.datasets.forEach(dataset_name=>)
      //let recent= [this.cookies.get('first_recent'),this.cookies.get('second_recent'),this.cookies.get('third_recent') ]
      return this.state.recent_datasets.slice(3,6).map((dataset_name, id) => {
        {
         // console.error('zzzzzzzzz',dataset_name[1]);

          //console.error('zzzzzzzzz',this.state.study_dict[dataset_name[0]]);
          //console.error('zzzzzzzzz',this.state.study_dict);
         // console.error('zzzzzzzzz',this.state.study_dict['blabla2223']);
         
          if (dataset_name[0] in this.state.study_dict)
          { 
            if (dataset_name[1] == this.state.study_dict[dataset_name[0]]["study_name"])
            {if (dataset_name.length==2 && dataset_name[0]!=0 && dataset_name[1]!=0 && dataset_name[0]!=null && dataset_name[1]!=null && this.state.study_dict) { return (<li key={id}><div className="dataset-div"><span onClick={() => this.deleteStudy(dataset_name[0])}><img className="buttonDeleteDataset" src={require('../delete-icon-green.png')}></img></span><span onClick={() => this.viewStudy(dataset_name[0])} className='span-dataset-datafile-near-image' style={(dataset_name[0] == this.state.current_study) ? { color: "darkorchid", cursor: "pointer" } : { color: "darkslategrey", cursor: "pointer" }}>{dataset_name[1] !== undefined && dataset_name[1] !== 'undefined' ? ( this.state.study_dict[dataset_name[0]]['study_name']) :(null)}</span></div></li>)
          }}
          }
         }
      });
    }

  }

  changeUppy = (split=false) => {
    this.uppy.setOptions({
      onBeforeFileAdded:  (currentFile, files) => {
        //console.error(currentFile.data)
        //var path = blobToUint8Array(currentFile.data)
        //var uuid = String.fromCharCode.apply(null, path)
        //var uuid = String.fromCharCode.apply(null, new Uint8Array(contentBuffer))
        //console.error(this.state.uid_dict,uuid)
         //path = this.state.uid_dict[uuid]
        //var path = readFile(currentFile,this).then() 
        //.then(path2 =>{console.error(path2)})
        //console.error(1,path)
        //console.error('public/cognito/xtrodesclient/' + this.state.auth.user_id['id'] + '/' + path + '/' + currentFile.name)
        const modifiedFile = {
          ...currentFile,
          // name: 'public/cognito/xtrodesclient/us-east-1:9a3f32b3-3f65-4cee-9a33-bf956b0d58ab/'+this.state.datasetname+'/'+currentFile.name
          //name: 'public/cognito/xtrodesclient/' + this.state.auth.user_id['id'] + '/' + path + '/' + currentFile.name
          name: 'public/cognito/xtrodesclient/'+this.state.auth.user_id['id']+'/'+this.state.datasetname+'/'+currentFile.name


        } 
        //this.state.file_uid_match['public/cognito/xtrodesclient/' + this.state.auth.user_id['id'] + '/' + path + '/' + currentFile.name] = uuid

        return modifiedFile

      }
    })
    this.uppy_split.setOptions({
      onBeforeFileAdded: (currentFile, files) => {
        const modifiedFile = {
          ...currentFile,
          // name: 'public/cognito/xtrodesclient/us-east-1:9a3f32b3-3f65-4cee-9a33-bf956b0d58ab/'+this.state.datasetname+'/'+currentFile.name
          name: 'public/cognito/xtrodesclient/' + this.state.auth.user_id['id'] + '/' + this.state.datasetname + '/' + currentFile.name

        }
        return modifiedFile

      }
    })
    return this.uppy;
  }

  

  downloadFile = (bucket, file_url) => {
    if (!Object.keys(this.state.last_hrefs_raw).includes(file_url))
    {
      
    let list = file_url.split(bucket + '/');
    var sp = file_url.split('-.~')[0].split('/').pop()
    var tmp  = file_url.split('/').pop()
    tmp = tmp.replace(sp+'_', '')
    // var file = require('fs').createWriteStream('./test.test');
    // s3_transformed.getObject({Bucket:bucket,Key:list[1]}).createReadStream().pipe(file);

    var params = { Bucket: bucket, Key: list[1], ResponseContentDisposition:'attachment; filename ="' + this.state.study_dict[this.state.current_study][this.state.datasetname]['name']  + '_'
    + tmp + '"'};
    var url = null;
    if (bucket == albumBucketName) {
      url = s3.getSignedUrl('getObject', params);
    }
    else {
      url = s3_transformed.getSignedUrl('getObject', params);
    }
    // console.log('The URL is', url);
    this.state.last_hrefs_raw[file_url] = url;

    return url;

  }
  else {

    return this.state.last_hrefs_raw[file_url]}
     }

  createAlbum = (albumName) => {


    if (albumName === null)
      return;
    albumName = albumName.trim();
    if (!albumName) {
      return alert("Dataset name must contain at least one non-space character.");
    }
    if (albumName.indexOf("/") !== -1) {
      return alert("Dataset names cannot contain slashes.");
    }
    if (/\W/.test(albumName))
    {
      return alert("Dataset names can only contain letters (A-Z, a-z), numbers (0-9), and underscores (_).");
    }
    if (albumName.length>40)
    {
      return alert("Dataset names can not be loger than 40 characters");
    }
    var url = django_url + "sessions/createv2/";
    var optionAxios = {
      headers: {
        'Authorization': 'Bearer ' + this.state.auth.user.signInUserSession.accessToken["jwtToken"],
        'Content-Type': "application/json"
      }
    };
    const data = { "Dateset_name": albumName,"study":this.state.current_study, 'identity': this.state.auth.user_id['id'],"IdToken": this.state.auth.user.signInUserSession.idToken["jwtToken"],
  }
    axios
      .post(url,data, optionAxios)
      .then((response) => {
        this.listAlbums2()
      })
    
    //var albumKey = albumName + "/";
    //// s3.headObject({Key: 'public/cognito/xtrodesclient/us-east-1:9a3f32b3-3f65-4cee-9a33-bf956b0d58ab/'+albumKey }, (err, data)=>{
    //s3.headObject({ Key: 'public/cognito/xtrodesclient/' + this.state.auth.user_id['id'] + '/' + albumKey }, (err, data) => {
//
    //  if (!err) {
    //    return alert("Dataset already exists.");
    //  }
    //  if (err.code !== "NotFound") {
    //    return alert("There was an error creating your Dataset: " + err.message);
    //  }
    //  // s3.putObject({Key: 'public/cognito/xtrodesclient/us-east-1:9a3f32b3-3f65-4cee-9a33-bf956b0d58ab/'+albumKey }, (err, data)=>{
    //  s3.putObject({ Key: 'public/cognito/xtrodesclient/' + this.state.auth.user_id['id'] + '/' + albumKey }, (err, data) => {
//
    //    if (err) {
    //      return alert("There was an error creating your Dataset: " + err.message);
    //    }
    //    alert("Successfully created Dataset.");
    //    this.listAlbums();
    //    this.setState({ datasetname: albumName });
    //    this.viewAlbum(this.state.datasetname,this.state.current_study);
//
    //  });
    //});
  }




  createStudy = (albumName) => {


    if (albumName === null)
      return;
    albumName = albumName.trim();
    if (!albumName) {
      return alert("Study name must contain at least one non-space character.");
    }
    if (albumName.indexOf("/") !== -1) {
      return alert("Study names cannot contain slashes.");
    }
    if (/\W/.test(albumName))
    {
      return alert("Study names can only contain letters (A-Z, a-z), numbers (0-9), and underscores (_).");
    }
    var url = django_url + "studies/create/";
    var optionAxios = {
      headers: {
        'Authorization': 'Bearer ' + this.state.auth.user.signInUserSession.accessToken["jwtToken"],
        'Content-Type': "application/json",
      }
    };
    const data = { "study_name": albumName
  }
    axios
      .post(url,data, optionAxios)
      .then((response) => {

        this.listAlbums2()
        alert("REMINDER\nTo update this change in the app, make sure your mobile device has active internet connection");
      }).catch((error) => {
      if (error.response) {
        return alert('Study with the same name already exists');
      }
    });
  
  }

   split_sessions = () => {

     var url = "https://bvo7j8uf49.execute-api.us-east-1.amazonaws.com/Prod/verify";
     var optionAxios = {
       headers: {
         'Authorization': this.state.auth.user.signInUserSession.idToken["jwtToken"],
         'Content-Type': 'application/json'
       }
     };
     const data = JSON.stringify({ "action": "split_dataset" });
     axios
       .post(url, data, optionAxios)
       .then((response) => {
         alert(response.data);        
         this.viewAlbum(this.state.datasetname,this.state.current_study)
           this.timer = setInterval(() => this.testStatus(), 1000);
       })
       .catch((error) => {
         alert(error.response.data);
       });
      
   }

  processXf1Files = () => {

    if (!this.check_file_sequenece(this.state.current_dataset_files)) 
    {return}
    var gen_xf1 = false
    var gen_xtr1 = false
    var overwrite_bt = false
    var overwrite_sd = false
    var xf1_count = 0
    var xtr1_count = 0

     for (let element of this.state.current_dataset_files)
   {
      if (element['photoKey'].split('.').pop().toLowerCase()=='xf1' ||
          element['photoKey'].split('.').pop().toLowerCase()=='xf2') {xf1_count+=1}
      if (element['photoKey'].split('.').pop().toLowerCase()=='xtr1') {xtr1_count+=1}

      if (parseInt(element['date']/1000)>parseInt(this.state.current_dataset_timestamp))
      { if (element['photoKey'].split('.').pop().toLowerCase()=='xf1' || element['photoKey'].split('.').pop().toLowerCase()=='xf2') gen_xf1=true
        if (element['photoKey'].split('.').pop().toLowerCase()=='xtr1') gen_xtr1=true
      }
      if (gen_xf1 && gen_xtr1) break
      
    };
    if (xf1_count<this.state.last_launch_xf1_count)
    {
      gen_xf1=true
    }
    if (xtr1_count<this.state.last_launch_xtr1_count)
    {
      gen_xtr1=true
    }
    //console.error(xf1_count,this.state.last_launch_xf1_count)
    this.state.current_output_dataset_files.forEach(element => {
      if (element['file_name'].endsWith('BT.edf') && gen_xtr1) {overwrite_bt = true}
      if (element['file_name'].endsWith('SD.edf') && gen_xf1) {overwrite_sd = true}
    });
    if (overwrite_bt || overwrite_sd )
    {
      var s = ''
      if ((overwrite_sd && overwrite_bt)) s = 'SD and BT files will be overwritten'
      else if (overwrite_sd) s = 'SD files will be overwritten'
      else s = 'BT files will be overwritten'
      if (!window.confirm(s))
      return;
    } 
    var url = "https://bvo7j8uf49.execute-api.us-east-1.amazonaws.com/Prod/verify";
    var optionAxios = {
      headers: {
        'Authorization': this.state.auth.user.signInUserSession.idToken["jwtToken"],
        'Content-Type': 'application/json'
      }
    };
    const data = JSON.stringify({ "dataset": this.state.datasetname, "action": "generate_edf",
    "session_id":this.state.study_dict[this.state.current_study][this.state.datasetname]['id'] });
    axios
      .post(url, data, optionAxios)
      .then((response) => {
        alert(response.data);
        this.setState({ current_dataset_status: "in progress" });
        this.setState({ current_dataset_progress: 0 });
        this.setState({ current_dataset_stage: "Generating EDF files" });
        this.viewAlbum(this.state.datasetname,this.state.current_study)

        //  this.timer = setInterval(() => this.testStatus(), 1000);
      })
      .catch((error) => {
        alert(error.response.data);
      });
      
  }

  select_all_proccesed = () => {
    
    if (this.state.current_dataset_files.length != 0)
    {
      var tmp = this.state.file_checkboxes
      if (this.state.file_checkboxes.includes(true))
        {tmp.fill(false)
        this.setState({file_links:[]})}
      else
        {
          var tmp2 = []
        this.state.current_dataset_files.forEach(element => {
          tmp2.push(element['photoUrl'])
        });
        tmp.fill(true)
        this.setState({file_checkboxes:tmp,file_links:tmp2})
      }
    }
  }
  select_all_output_proccesed = () => {
    
    if (this.state.current_output_dataset_files.length != 0)
    {
      var tmp = this.state.output_file_checkboxes
      if (this.state.output_file_checkboxes.includes(true))
        {tmp.fill(false)
        this.setState({output_file_links:[]})}
      else
        {
          var tmp2 = []
        this.state.current_output_dataset_files.forEach(element => {

          tmp2.push(element['outputurl'])
        });
        tmp.fill(true)
        this.setState({output_file_checkboxes:tmp,output_file_links:tmp2})
      }
    }
  }


   testStatus = () => {

    //var docClient = new AWS.DynamoDB.DocumentClient();
    var table = "edf_statuses";

    var identity = this.state.auth.user_id['id'];
    var dataset = this.state.datasetname;
    var files_processed = this.state.current_dataset_processed_files
    var that = this;
    var params = {
      TableName: table,
      Key: {
        "identity": identity,
        "dataset": dataset
      }
    };
    this.docClient.get(params, function (err, data) {
      if (err) {
        console.error("Unable to read item. Error JSON:", JSON.stringify(err, null, 2));
      } else {
        if (data["Item"] != undefined) {
          that.setState({ current_dataset_status: data["Item"]["status"] },
            () => {
              if (data["Item"]["status"] != "in progress") {
                //console.log(that.state.current_dataset_status)
                clearInterval(that.state.timer);
                that.setState({ timer: null })
              }

            })
          that.setState({ current_dataset_stage: data["Item"]["stage"] })
          that.setState({current_dataset_timestamp:data["Item"]["start"]})
          that.setState({last_launch_xf1_count:data["Item"]["last_launch_xf1_count"]})
          that.setState({last_launch_xtr1_count:data["Item"]["last_launch_xtr1_count"]})


          that.setState({ current_dataset_errors: data["Item"]["errors"] })
          that.setState({ current_dataset_progress: data["Item"]["progress"] })
          that.setState({ current_dataset_processed_files: data["Item"]["files_uploaded"] },
            () => {
              if (that.state.current_dataset_processed_files != files_processed && that.state.current_dataset_processed_files !=null
                && that.state.previous_dataset==that.state.datasetname) {
                that.viewAlbum(that.state.datasetname,that.state.current_study, false)
              }

            }
          );
        }
        else{

          clearInterval(that.state.timer);
                that.setState({ timer: null })
                that.setState({ current_dataset_status: null })
        }
      }
    that.setState({ previous_dataset: that.state.datasetname });
    }
    
    )
    
    //if (this.state.current_dataset_status=="completed")
    //{
    //  console.log("fml")
    //  clearInterval(this.state.timer);
    //    this.setState({timer:null})
    //    return;
    //}

    //if (res["status"]=="in progress"){


    //}

    //this.viewAlbum(this.state.datasetname);
  }

  check_file_sequenece(files)
  {
   var  l = files.length
    var patt = /NEUR\d{4}.XF1/i;
    var xf1files = []
    var arr2=[]
    while (l)
    {
      l-=1;
      if (files[l]["photoKey"].match(patt))
      xf1files.push(files[l]["photoKey"])
    }
    var  arr2=(xf1files.slice(0, -1).filter((x, i) => parseInt(xf1files[i + 1].slice(4,-4)) - parseInt(x.slice(4,-4)) < -1) )
    arr2 = arr2.map(v=> parseInt(v.slice(4,-4)-1))
    if (arr2 instanceof Array && arr2.length>0)
    {
      if (!window.confirm('Probable missing file at number ' + arr2.join(' ')+'. Do you want to continue?'))
            {
              return false
            }           
      else {return true}
    }
    else {return true}
  }

  deleteAlbum = (albumName,study) => {
    // var albumKey = encodeURIComponent(albumName) + "/";
    if (!window.confirm('Are you sure you want to delete ' + albumName + ' Dataset?. Everything there will be lost!!!. Are you sure?'))
      return;
    if (!window.confirm('Please confirm deletion of ' + albumName + ' again!'))
      return;
    var url = django_url + "sessions/remove/" + this.state.study_dict[study][albumName]['id'] + '/';
     var optionAxios = {
       headers: {
         'Authorization': 'Bearer ' + this.state.auth.user.signInUserSession.accessToken["jwtToken"],
       }
     };
     var that = this
     const data = { "is_deleted": true}
     axios
       .put(url,data, optionAxios)
       .then((response) => {
         that.setState({datasetname:null});
         delete this.state.study_dict[study][albumName]
         this.listAlbums2()
       })

  }

  cleanSplitFiles=()=>{
    // var albumKey = encodeURIComponent(albumName) + "/";
    if(!window.confirm('Are you sure you want to clear the files?'))
      return;
    var albumKey = 'split' + "/";

  
    // s3.listObjects({ Prefix:'public/cognito/xtrodesclient/us-east-1:9a3f32b3-3f65-4cee-9a33-bf956b0d58ab/'+ albumKey }, (err, data)=>{
    s3.listObjects({ Prefix:'public/cognito/xtrodesclient/' +this.state.auth.user_id['id']+'/'+ albumKey }, (err, data)=>{

      if (err) {
        return alert("There was an error deleting your Dataset: ", err.message);
      }
      var objects = data.Contents.map(function(object) {
        return { Key: object.Key };
      });
      s3.deleteObjects(
        {
          Delete: { Objects: objects, Quiet: true }
        },
        (err, data)=>{
          if (err) {
            return alert("There was an error deleting your Dataset: ", err.message);
          }
          alert("Successfully deleted Dataset.");
          this.listAlbums();
          this.setState({datasetname:null});
          this.setState({current_dataset_files:[]});
          this.setState({current_output_dataset_files:[]});
          
          // listAlbums();
        }
      );
    });

    if (albumKey=='split/')
    return;
    s3_transformed.listObjects({ Prefix:'public/cognito/xtrodesclient/' +this.state.auth.user_id['id']+'/'+ albumKey }, (err, data)=>{

      if (err) {
        return alert("There was an error deleting your transformed dataset: ", err.message);
      }
      var objects = data.Contents.map(function(object) {
        return { Key: object.Key };
      });
      s3_transformed.deleteObjects(
        {
          Delete: { Objects: objects, Quiet: true }
        },
        (err, data)=>{
          if (err) {
            return alert("There was an error deleting your transfored dataset: ", err.message);
          }
          // alert("Successfully deleted Dataset.");
          // this.listAlbums();
          // this.setState({datasetname:null});
          // this.setState({current_dataset_files:[]});
          // this.setState({current_output_dataset_files:[]});
          
          // listAlbums();
        }
      );
    });

  }


  moveSession = () => {
    // var albumKey = encodeURIComponent(albumName) + "/";
    if (this.state.study_to_move!=null)
    {
    var url =  django_url + "sessions/move/" + this.state.study_dict[this.state.current_study][this.state.datasetname]['id'] + '/';
     var optionAxios = {
       headers: {
         'Authorization': 'Bearer ' + this.state.auth.user.signInUserSession.accessToken["jwtToken"],
       }
     };
     const data = { "study": this.state.study_to_move}
     axios
       .put(url,data, optionAxios)
       .then((response) => {
         this.setState({datasetname:null,current_dataset_files:[],current_output_dataset_files:[]})
        delete this.state.study_dict[this.state.current_study][this.state.datasetname]
         this.close_move_window()
         this.listAlbums2()
       })
      }
    }


  deleteStudy = (study) => {
    // var albumKey = encodeURIComponent(albumName) + "/";
    if (!window.confirm('Are you sure you want to delete the study?. Everything there will be lost!!!. Are you sure?'))
      return;
    if (!window.confirm('Please confirm deletion again!'))
      return;
    var url = django_url +  "studies/remove/" + study + '/';
     var optionAxios = {
       headers: {
         'Authorization': 'Bearer ' + this.state.auth.user.signInUserSession.accessToken["jwtToken"],
       }
     };
     const data = { "is_deleted": true}
     axios
       .put(url,data, optionAxios)
       .then((response) => {
         delete this.state.study_dict[study]
         this.listAlbums2()
         alert("REMINDER\nTo update this change in the app, make sure your mobile device has active internet connection");
       })
      }

  render() {
    return this.props.isauthenticatedtoggle ? (<div><div className="mobile-alternate-message first-screen-message">Currently we do not support Mobile devices</div><div className="main-display-device"><div className="flexbox-container">
      
        

      <div className="flexbox-item flexbox-item-3" id="aws-list">

      {    <div className="recent-container"> <div className="flexbox-headline">Recently used</div>
          <div id="dataset-list_inner_recent">{this.displayRecentStudies()}</div></div>  }
     <div id="dataset-list"> <div className="flexbox-headline">Select or Create study</div>

       <div id="dataset-list_inner">{this.displayStudies()}</div></div>
       <div><button className="button" onClick={() => this.createStudy(prompt('Enter Study Name:'))}>Create New Study</button></div>
     {/* <div><button className="button" onClick={this.show_split_history.bind(this)}>Split Sessions</button></div> */}


   </div>

      <div className="flexbox-item flexbox-item-1" id="aws-list">
      {    <div className="recent-container"> <div className="flexbox-headline">Recently used</div>
          <div id="dataset-list_inner_recent">{this.displayRecentDatasets()}</div></div>  }
        <div id="dataset-list"> <div className="flexbox-headline">Select or Create Dataset</div>
          <div id="dataset-list_inner">{this.displayDatasets2()}</div></div>

        {this.state.current_study !== null ? (<div><button className="button" onClick={() => this.createAlbum(prompt('Enter Dataset Name:'))}>Create New Dataset</button></div>): (null)}
        {this.state.datasetname !== null ? (<div><button className="button" onClick={() => this.rename_dataset(this.state.datasetname,this.state.current_study,prompt('Enter Dataset Name:'))}>Rename</button></div>) : (null)}
        {this.state.datasetname !== null ? (<div><button className="button" onClick={()=>this.setState({show_move: true})}>Move</button></div>) : (null)}

        <Modal show={this.state.show_split} onClose={this.close_split_history.bind(this)} transitionSpeed={100} closeOnOuterClick={true} 
        containerStyle={{width: 'auto' ,height:'40%'}}
        containerClassName="table-test">

<div className="split-files">
          {(this.state.datasetname != null) && this.displayDatasetFiles()}
        </div>
        
          Previous split result:

          <JsonToTable json={this.state.split_history}  />
          <div className="box">
        <div className="item1">
        <div><button className="button-split" onClick={this.handleOpen}>Upload Data</button></div>
          <div><button className="button-split" onClick={() => this.cleanSplitFiles()}>Clear files</button></div>
          </div>
          <div class="item2"><button className="button-split-confirm" onClick={() => this.split_sessions()}>Split sessions</button></div>
          </div>

        <DashboardModal
              uppy={this.uppy_split}
              open={this.state.modalOpen}
              onRequestClose={this.handleClose}
            />
</Modal>

<Modal show={this.state.show_move} onClose={this.close_move_window.bind(this)} transitionSpeed={100} closeOnOuterClick={true} 
        containerStyle={{width: 'auto' ,height:'50%'}}
        containerClassName="table-test">
<div className="split-files">
          <div >{this.displayStudiesForMove()}</div>
        </div>
        <div className="box">
        <div className="item1">
          </div>
          <div className="item2"><button className="button-split-confirm" onClick={() => this.moveSession()}>Move</button></div>
          </div>
</Modal>
      </div>

      
      <div className="flexbox-item flexbox-item-2">
        {this.state.current_dataset_status !== "completed" && this.state.current_dataset_status !== null && this.state.current_dataset_status !== "renaming" ? (<div><div>{this.state.current_dataset_stage}</div>
          <div><ProgressBar completed={Math.floor(this.state.current_dataset_progress)} labelAlignment="outside" labelColor="#010000"/></div></div>) : (null)}
          {this.state.current_dataset_errors!== "" && this.state.current_dataset_errors !== null ? (<div><div>{this.state.current_dataset_errors}</div>
          </div>) : (null)}
          {this.state.current_dataset_status == "renaming" ? (<div><div>Renaming</div></div>): (null)}
          <div className="flexbox-headline"> <p style={{ color: "darkorchid", display: "inline",whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{this.state.datasetname !== null ? (this.state.study_dict[this.state.current_study][this.state.datasetname]['name']) :(null)}</p></div>
          <div className="flexbox-headline"> <p style={{ color: "darkorchid", display: "inline",whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{this.state.current_dataset_time !== null ? (this.state.current_dataset_time) :(null)}</p></div>
          {this.state.current_dataset_files.length !== 0 ?
        (
        <div>
        <button ><img src={select_all}   alt="select all" onClick={this.select_all_proccesed}/></button>

        <button  ><img src={download_all}   alt="download selected" onClick={this.downloadSelectedRaw}/> </button>
        {/* <button className="checkbox_buttons" onClick={this.select_all_proccesed}>Select all</button> */}
        <button ><img src={delete_all}  alt="delete selected" onClick={this.deleteSelectedRaw}/></button>
        {/* <button className="checkbox_buttons" onClick={this.deleteSelectedRaw}>Delete selected</button> */}

        </div>):(null)}
        <div className="uploaded-files">
        {/* <button className="checkbox_buttons" onClick={this.downloadSelectedRaw}>Download selected</button> */}
        
          <div id="aws-list-item-contents">{(this.state.datasetname != null) && this.displayDatasetFiles()}</div>
        </div>
        <div className="flexbox-headline">Processed Files</div>
        {this.state.current_output_dataset_files.length !== 0 ?
        (
        <div>
        <button ><img src={select_all}   alt="select all" onClick={this.select_all_output_proccesed}/></button>

        <button  ><img src={download_all}   alt="download selected" onClick={this.downloadSelectedOutput}/> </button>
        {/* <button className="checkbox_buttons" onClick={this.select_all_proccesed}>Select all</button> */}
        {/* <button className="checkbox_buttons" onClick={this.deleteSelectedRaw}>Delete selected</button> */}

        </div>):(null)}
        <div className="uploaded-files"><div id="aws-list-item-contents-output">{this.displayOutputDatasetFiles()}</div></div>
        <div className="DashboardContainer">
          <div>
            <button className="button" onClick={this.handleOpen}>Upload Data</button>
        {this.state.datasetname !== null ? (<div><button className="button" onClick={() => this.processXf1Files()}>Generate EDF Files</button></div>) : (null)}

            <DashboardModal
              uppy={this.uppy}
              open={this.state.modalOpen}
              onRequestClose={this.handleClose}
            />
          </div>
        </div>
        <p className='version'><small>Ver 2.2.0</small></p>
      </div>
     


    </div></div></div>) : (<div><div className="mobile-alternate-message first-screen-message">Currently we do not support Mobile devices</div><div className="main-display-device"><div className='first-screen-message'>Welcome to X-trodes’ database website. This is the place to keep your recordings. We will take it from here. To proceed, please<a href="/login"> Log in </a>or<a href="/register"> Register</a></div></div></div>)
  }
};



  // <Dashboard uppy={uppy}/>
//   {this.uppy.setOptions({onBeforeFileAdded: (currentFile, files) => {
//     const modifiedFile = {
//     ...currentFile,
//     name: this.state.datasetname+'/'+currentFile.name
//   }
//   return modifiedFile

// }})}
// closeModalOnClickOutside