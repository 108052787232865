import React, { Component } from 'react';
import FormErrors from "../FormErrors";
import Validate from "../utility/FormValidation";
import {Auth} from 'aws-amplify' //the {} import the module into the current scope. not sure exactly what it means
import showPwdImg from '../../view.svg';
import hidePwdImg from '../../hide.svg';
import cover from '../../Cover-002.png';
import ReactTooltip from "react-tooltip";

class Register extends Component {
  state = {
    username: "",
    email: "",
    password: "",
    confirmpassword: "",
    hidden: true,
    errors: {
      cognito: null,
      blankfield: false,
      passwordmatch: false
    }
  }

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false,
        passwordmatch: false
      }
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    // Form validation
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error }
      });
    }

    // AWS Cognito integration here
    const{username,email,password} = this.state;
    try{
      const signUpResponse = await Auth.signUp({
        username,
        password,
        attributes:{
          email:email
        }
      });
      console.log(signUpResponse);
      this.props.history.push("/login");

    }catch(error){
      let err = null;
      console.log(error.message);
      !error.message ? err={message:error}: err=error;
      this.setState(
        {errors:{
          ...this.state.errors,
          cognito:error
        }
        }
      )

    }
  };

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  }

  toggleShow =() =>{
    this.setState({ hidden: !this.state.hidden });
  }

  render() {
    return (
      <div className="register_root">
      <section className="section auth">
        
        <div className="container" >
          <h1 style={{color:"white"}}>Register</h1>
          <FormErrors formerrors={this.state.errors} />

          <form onSubmit={this.handleSubmit}>
            <div className="field">
              <p className="control">
                <input 
                  className="input" 
                  type="text"
                  id="username"
                  aria-describedby="userNameHelp"
                  placeholder="Enter username"
                  value={this.state.username}
                  onChange={this.onInputChange}
                />
              </p>
            </div>
            <div className="field">
              <p className="control has-icons-left has-icons-right">
                <input 
                  className="input" 
                  type="email"
                  id="email"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  value={this.state.email}
                  onChange={this.onInputChange}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-envelope"></i>
                </span>
              </p>
            </div>
            <div className="field">
              <p className="control has-icons-left" data-tip data-for="registerTip">
                <input 
                  className="input" 
                  type={this.state.hidden ? "password" : "text"}
                  id="password"
                  placeholder="Password"
                  value={this.state.password}
                  onChange={this.onInputChange}
                />
                 <img
          title={this.state.hidden ? "Hide password" : "Show password"}
          src={this.state.hidden ? hidePwdImg : showPwdImg}
          onClick={() => this.toggleShow()}
          style={{cursor: "pointer",
            position: "absolute",
            width: "20px",
            right: "8px",
            top: "8px"}}
        />
                <span className="icon is-small is-left">
                  <i className="fas fa-lock"></i>
                </span>
              </p>
            </div>
            <div className="field">
              <p className="control has-icons-left" data-tip data-for="registerTip" >
                <input 
                  className="input" 
                  type={this.state.hidden ? "password" : "text"}
                  id="confirmpassword"
                  placeholder="Confirm password"
                  value={this.state.confirmpassword}
                  onChange={this.onInputChange}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-lock"></i>
                </span>
              </p>
            </div>
            <div className="field">
              <p className="control">
                <a href="/forgotpassword">Forgot password?</a>
              </p>
            </div>
            <div className="field">
              <p className="control">
                <button className="button is-success">
                  Register
                </button>
              </p>
            </div>
          </form>
          <p className='passwordinstruction'>Password must be at least 8 characteres, contain at least one uppercase, one lowercase letter, a number and a special character</p>
        </div>
        <ReactTooltip id="registerTip" place="top" effect="solid" multiline="true">
        Password must be at least 8 characteres, contain at least one uppercase, one lowercase letter, a number and a special character
      </ReactTooltip>
      </section>
      </div>
    );
  }
}

export default Register;